import React from "react";
import { useSelector } from "react-redux";
import MapComponent from "../../components/google-maps";
import { getSpacePriceConfigFromSpot, getSpacePriceFromConfig } from "../../utils/spots";
import SpaceListMapCard from "../google-maps/map-card";

function Map({ showMap = false, width = "100%", height = "100%", center = { lat: 41.609092, lng: -0.8873565 } }) {
  const spaces = useSelector((state) => state.spaceList.spaces);
  const asset = useSelector((state) => state.spaceList.asset);
  const spots = useSelector((state) => (asset?.id ? state.misc.spots[asset.id] ?? [] : []));
  const spaceSpot = useSelector((state) => state.spaceList.filters.spot);

  const currentAsset = asset;
  const mapCenter = currentAsset ? { lat: currentAsset.latitude, lng: currentAsset.longitude } : center;

  const selectedSpot = spots.find((spot) => spot.slug === spaceSpot);
  const priceConfig = selectedSpot?.data?.price ?? getSpacePriceConfigFromSpot(spaceSpot);

  const spacesWithPrice = spaces.map((s) => ({
    ...s,
    display_price: getSpacePriceFromConfig(s, priceConfig),
  }));

  return (
    <MapComponent
      MapCard={SpaceListMapCard}
      showMap={showMap}
      width={width}
      height={height}
      center={mapCenter}
      priceMarkers={spacesWithPrice}
    />
  );
}

export default Map;
