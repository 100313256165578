import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { Trans } from "@lingui/macro";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useLingui } from "@lingui/react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { currencySymbol } from "../../utils/currencyUtils";
import BackLongIcon from "../../icons/back-long-icon";
import FilterTypePopover from "../../containers/spaces-list/filter-type-popover";
import FilterPricePopover from "../../containers/spaces-list/filter-price-popover";
import FilterSizePopover from "../../containers/spaces-list/filter-size-popover";
import FilterMoreDialog from "../../containers/spaces-list/filter-more-dialog";
import FilterDateDialog from "../../containers/spaces-list/filter-date-dialog";
import SearchIcon from "../../icons/search-icon";
import theme from "../../theme";
import { formatDateUtc, isDateValid } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  filter: {
    borderBottom: theme.borders[6],
  },
  filterItemRow: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    borderRight: theme.borders[6],
  },
  filterItemColumn: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    borderRight: theme.borders[6],
    cursor: "pointer",
    "&:hover": {
      boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset",
    },
  },
  customInput: {
    width: "100%",
    fontSize: "14px",
  },
}));

function SpaceListFilter({
  assets = [],
  asset,
  filters = {
    types: [],
    services: [],
    features: [],
  },
  onSearch = () => {},
  dateStart = "",
  dateEnd = "",
}) {
  const classes = useStyles(theme);
  const { i18n } = useLingui();

  const [moreFilterOpen, setMoreFilterOpen] = useState(false);
  const [typePopover, setTypePopover] = useState(null);
  const [pricePopover, setPricePopover] = useState(null);
  const [sizePopover, setSizePopover] = useState(null);
  const [openDateDialog, setOpenDateDialog] = useState(false);

  const loading = assets.length === 0;
  const sortedByNameAssets = assets.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const handleOpenTypeFilter = (event) => {
    setTypePopover(event.currentTarget);
  };

  const handleOpenPriceFilter = (event) => {
    setPricePopover(event.currentTarget);
  };

  const handleOpenSizeFilter = (event) => {
    setSizePopover(event.currentTarget);
  };

  const showSpaceTypeNames = () => {
    let spacesTypesSelected = filters.types.map((t) => t.name).join(", ");

    if (spacesTypesSelected.length > 15) {
      spacesTypesSelected = `${spacesTypesSelected.substring(0, 12)}...`;
    }
    if (spacesTypesSelected.length === 0) {
      spacesTypesSelected = i18n._(`Todos`);
    }
    return spacesTypesSelected;
  };

  const showCharacteristicsNames = () => {
    let spacesCharacteristicsSelected = filters.features.map((t) => i18n._(`${t.name}`)).join(", ");

    if (spacesCharacteristicsSelected.length > 15) {
      spacesCharacteristicsSelected = `${spacesCharacteristicsSelected.substring(0, 12)}...`;
    }
    if (spacesCharacteristicsSelected.length === 0) {
      spacesCharacteristicsSelected = i18n._(`Todos`);
    }
    return spacesCharacteristicsSelected;
  };

  const handleClose = () => {
    setTypePopover(null);
    setPricePopover(null);
    setSizePopover(null);
  };

  const typeOpen = Boolean(typePopover);
  const priceOpen = Boolean(pricePopover);
  const sizeOpen = Boolean(sizePopover);

  return (
    <Box display="flex" flexDirection="row" height="68px" width="100%" className={classes.filter} color="text.primary">
      <FilterDateDialog
        open={openDateDialog}
        onClose={() => {
          setOpenDateDialog(false);
        }}
      />
      <Box display="flex" width="100%">
        <Box flexGrow="10" className={classes.filterItemRow}>
          <Box mr={1} style={{ transform: "translateY(12%)" }}>
            <SearchIcon width="20px" height="17px" />
          </Box>
          <Autocomplete
            options={sortedByNameAssets}
            loading={loading}
            getOptionLabel={(option) => option.name}
            className={classes.customInput}
            onChange={(event, asset) => {
              if (asset) {
                onSearch(asset.id);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} className={classes.customInput} placeholder={asset?.name} />
            )}
          />
        </Box>
        <Box flexGrow="2" display="flex">
          <Box
            className={classes.filterItemColumn}
            flexDirection="column"
            onClick={() => {
              setOpenDateDialog(true);
            }}
          >
            <Typography variant="subtitle1">
              <Trans>Ingreso / Salida</Trans>
            </Typography>
            <Box mt={1}>
              {isDateValid(dateStart) ? (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="body1">{formatDateUtc(dateStart, "DD/MM/YYYY")}</Typography>
                  {isDateValid(dateEnd) && (
                    <>
                      <BackLongIcon
                        style={{
                          transform: "rotateZ(180deg)",
                          width: "10px",
                          marginLeft: theme.spacing(1 / 2),
                          marginRight: theme.spacing(1 / 2),
                        }}
                      />
                      <Typography variant="body1">{formatDateUtc(dateEnd, "DD/MM/YYYY")}</Typography>
                    </>
                  )}
                </Box>
              ) : (
                <Typography variant="body1">-</Typography>
              )}
            </Box>
          </Box>
        </Box>

        <Box flexGrow="2" display="flex">
          <FilterTypePopover
            open={typeOpen}
            anchorEl={typePopover}
            values={filters.types}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handleClose}
          />
          <Box onClick={handleOpenTypeFilter} className={classes.filterItemColumn} overflow="none">
            <Typography variant="subtitle1">
              <Trans>Ubicación</Trans>
            </Typography>
            <Box mt={1}>
              <Typography variant="body1">{showSpaceTypeNames()}</Typography>
            </Box>
          </Box>
        </Box>
        {asset?.config?.show_space_price && (
          <Box flexGrow="2" display="flex">
            <FilterPricePopover
              open={priceOpen}
              anchorEl={pricePopover}
              values={[filters.priceStart, filters.priceEnd]}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handleClose}
            />
            {asset.config?.show_space_price && (
              <Box onClick={handleOpenPriceFilter} className={classes.filterItemColumn}>
                <Typography variant="subtitle1">
                  <Trans>Precio</Trans>
                </Typography>
                <Box mt={1}>
                  <Typography variant="body1">{`${filters.priceStart}${currencySymbol()} - ${
                    filters.priceEnd
                  }${currencySymbol()}`}</Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}
        <Box flexGrow="2" display="flex">
          <FilterSizePopover
            open={sizeOpen}
            anchorEl={sizePopover}
            values={[filters.sizeStart, filters.sizeEnd]}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handleClose}
          />
          <Box onClick={handleOpenSizeFilter} className={classes.filterItemColumn}>
            <Typography variant="subtitle1">
              <Trans>Tamaño del espacio</Trans>
            </Typography>
            <Box mt={1 / 2}>
              <Typography variant="body1">
                {`${filters.sizeStart}`} m<sup>2</sup> - {`${filters.sizeEnd}`} m<sup>2</sup>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box flexGrow="1" display="flex">
          <FilterMoreDialog
            open={moreFilterOpen}
            onClose={() => {
              setMoreFilterOpen(false);
            }}
          />
          <Box
            onClick={() => {
              setMoreFilterOpen(true);
            }}
            className={classes.filterItemColumn}
          >
            <Typography variant="subtitle1">
              <Trans>Características</Trans>
            </Typography>
            <Box mt={1}>
              <Typography variant="body1">{showCharacteristicsNames()}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SpaceListFilter;
