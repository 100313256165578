import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { addSpacesFilterThunk } from "../../redux/actions/space-list-actions";
import FilterDateDialog from "../../components/spaces-list/filter-date-dialog";

function SpaceListFilterDate({ open = false, onClose, ...rest }) {
  const dispatch = useDispatch();

  const start = useSelector((state) => state.spaceList.filters.start);
  const end = useSelector((state) => state.spaceList.filters.end);
  const locale = useSelector((state) => state.user.data.locale);

  const addFilter = (dateStart, dateEnd) => {
    dispatch(
      addSpacesFilterThunk({
        start: new Date(dateStart).toUTCString(),
        end: new Date(dateEnd).toUTCString(),
      }),
    );
  };

  return (
    <FilterDateDialog
      open={open}
      onClose={onClose}
      startDate={start}
      endDate={end}
      onSelectDates={addFilter}
      locale={locale}
      calendarProps={{
        selectRange: true,
      }}
      {...rest}
    />
  );
}

export default SpaceListFilterDate;
