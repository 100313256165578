import React from "react";
import { useSelector } from "react-redux";
import MapCardComponent from "../../components/google-maps/map-card";
import { getSpacePriceConfigFromSpot, getSpacePriceFromConfig } from "../../utils/spots";

const MapCard = () => {
  const space = useSelector((state) => state.googleMaps.space);
  const status = useSelector((state) => state.googleMaps.spaceStatus);
  const currentAsset = useSelector((state) => state.misc.currentAsset);
  const spots = useSelector((state) =>
    currentAsset && parseInt(currentAsset.id, 10) ? state.misc.spots[parseInt(currentAsset.id, 10)] ?? [] : [],
  );
  const spot = (typeof localStorage !== "undefined" && localStorage.getItem("AssetSpotCardSelected")) || null;
  const selectedSpot = spots.find((s) => s.slug === spot);
  const priceConfig = selectedSpot?.data?.price ?? getSpacePriceConfigFromSpot(spot);

  return (
    <MapCardComponent
      space={space}
      status={status}
      spot={spot}
      priceConfig={priceConfig}
      price={getSpacePriceFromConfig(space, priceConfig)}
    />
  );
};

export default MapCard;
